@import url(https://fonts.googleapis.com/css?family=Lato|Open+Sans&display=swap);
/* Scrollbar styles */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(40, 40, 40, 1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(240, 240, 240, 1);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(18, 18, 18, 1);
}

body {
  margin: 0;
  font-family: "Roboto", Helvetica, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 14pt;
  color: rgba(240, 240, 240, 1);
  background-color: rgba(40, 40, 40, 1);
  /*min-width: 768px;*/
}

#container {
  margin: 75px 100px 20px 100px;
  height: 100%
}

hr {
  width: 100%;
  margin: 10px 0;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
}

a {
  color: rgba(66, 135, 245, 1);
  text-decoration: none;
}
